@import '~antd/dist/antd.less';
@import "styles/variables";

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Light.otf) format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Bold.otf) format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-RegularItalic.otf) format('opentype');
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-LightItalic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-BoldItalic.otf) format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';

.ant-layout {
    background: url("assets/background.svg") no-repeat center center;
    background-size: cover;
}

.auth-layout {
    min-height: 100vh;

    &__logo-container {
        align-self: center;
        margin-top: 100px;

        @media (max-width: @ib-breakpoint-small) {
            margin-top: @ib-padding-xx-large;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;

        @media (max-width: @ib-breakpoint-small) {
            align-items: flex-end;
            margin: 0;
        }
    }
}
