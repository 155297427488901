@import "../../../styles/variables";

.ib-checkbox {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid @ib-checkbox-border-color;
  background: @ib-white-color;
  transition: all 0.2s ease-out;
  cursor: pointer;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    opacity: 0;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  svg {
    position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    color: @ib-white-color;
    top: -1px;
    left: -1px;
  }

  &:hover {
    border: 1px solid @ib-primary-color;
  }

  &_checked {
    border: 1px solid @ib-primary-color;
    background: @ib-primary-color;

    svg {
      display: block;
    }
  }

  &_disabled {
    cursor: not-allowed;
    border: 1px solid @ib-checkbox-border-color !important;
    background: @ib-checkbox-disabled-bg-color !important;

    svg {
      color: @ib-checkbox-border-color;
    }
  }
}
