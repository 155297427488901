@import "../../../styles/variables";

.ib-typography {
  color: @gray-gray-900;

  h1 {
    font-size: @ib-font-size-xxx-large;
    line-height: @ib-line-height-xxx-large;
    font-weight: @ib-font-weight-bold;
    margin: 0;
    word-break: break-word;
  }

  h2 {
    font-size: @ib-font-size-xx-large;
    line-height: @ib-line-height-xx-large;
    font-weight: @ib-font-weight-bold;
    margin: 0;
    word-break: break-word;
  }

  h3 {
    font-size: @ib-font-size-x-large;
    line-height: @ib-line-height-x-large;
    font-weight: @ib-font-weight-bold;
    margin: 0;
    word-break: break-word;
  }

  h4 {
    font-size: @ib-font-size-large;
    line-height: @ib-line-height-large;
    font-weight: @ib-font-weight-bold;
    margin: 0;
    word-break: break-word;
  }

  h5 {
    font-size: @ib-font-size-medium;
    line-height: @ib-line-height-medium;
    font-weight: @ib-font-weight-bold;
    margin: 0;
    word-break: break-word;
  }

  &__paragraph {
    word-break: break-word;
    color: @gray-gray-900;

    &_normal {
      font-size: @ib-font-size-base;
      line-height: @ib-line-height-base;
      font-weight: @ib-font-weight-normal;
    }

    &_secondary {
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-base;
      font-weight: @ib-font-weight-normal;
    }

    &_descriptor {
      font-size: @ib-font-size-x-small;
      line-height: @ib-line-height-x-small;
      font-weight: @ib-font-weight-normal;
    }

    &_strong {
      font-weight: @ib-font-weight-bold;
    }

    &_disabled {
      color: @gray-gray-300;
    }

    &_light {
      color: @gray-gray-400;
    }

    &_ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &_error {
      color: @red-red-600;
    }
  }
}
