@import "../../styles/variables";

.ib-forgot-password-page {
    padding: @ib-padding-large;
    position: relative;
    border-radius: @ib-border-radius-small;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
    background: @white-white-50;
    width: 468px;

    @media (max-width: @ib-breakpoint-small) {
        width: 100%;
        border-radius: @ib-border-radius-x-large @ib-border-radius-x-large 0 0;
    }

    .ib-spin {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: @ib-border-radius-small;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: @white-white-50;
        opacity: 0.85;
        z-index: 2;

        @media (max-width: @ib-breakpoint-small) {
            border-radius: @ib-border-radius-x-large @ib-border-radius-x-large 0 0;
        }

        span {
            font-size: 64px;
        }
    }

    &__form {
        margin: 32px 0;
    }

    &__title {
        color: @gray-gray-900;
        font-size: @ib-font-size-xx-large;
        font-weight: @ib-font-weight-bold;
        line-height: @ib-line-height-xx-large;
        margin-bottom: @ib-padding-base;

        @media (max-width: @ib-breakpoint-small) {
            text-align: center;
        }
    }

    &__input-label {
        color: @gray-gray-900;
        font-size: @ib-font-size-small;
        font-weight: @ib-font-weight-normal;
        line-height: @ib-line-height-base;
        margin-bottom: @ib-auth-page-input-label-margin;
    }

    &__button {
        margin-bottom: @ib-padding-base;

        .ib-button {
            width: 100%;
        }
    }

    &__description {
        margin-top: 4px;
        color: @gray-gray-400;
        font-size: @ib-font-size-x-small;
        font-weight: @ib-font-weight-normal;
        line-height: @ib-line-height-x-small;
    }

    &__success-description {
        display: flex;
        gap: @ib-padding-base;
        flex-direction: column;
        margin: 32px 0;
        font-size: @ib-font-size-base;
        font-weight: @ib-font-weight-normal;
        line-height: @ib-line-height-base;
    }

    &__register {
        color: @gray-gray-300;
        font-size: @ib-font-size-small;
        font-weight: @ib-font-weight-normal;
        line-height: @ib-line-height-base;
        text-align: center;
    }

    .ib-button_link {
        color: @blue-blue-700;
        font-size: @ib-font-size-small;
        font-weight: @ib-font-weight-normal;
        line-height: @ib-line-height-base;
        text-align: center;
    }
}
