.ib-icon {
  display: inline-flex;
  flex-shrink: 0;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &.i-icon-spin {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
