@import "../../styles/variables";

.ib-logout-page {
    padding: 48px;
    position: relative;
    border-radius: @ib-border-radius-small;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
    background: @white-white-50;
    width: 356px;
    text-align: center;

    @media (max-width: @ib-breakpoint-small) {
        width: 100%;
        border-radius: @ib-border-radius-x-small;
        margin: @ib-padding-base;
    }

    .ib-spin {
        height: 64px;
        margin-bottom: 32px;
        span {
            font-size: 64px;
        }
    }

    &__title {
        color: @gray-gray-900;
        font-size: @ib-font-size-xx-large;
        font-weight: @ib-font-weight-bold;
        line-height: @ib-line-height-xx-large;
        margin-bottom: @ib-padding-base;
    }

    &__description {
        display: flex;
        flex-direction: column;
        gap: @ib-padding-base;
        color: @gray-gray-900;
        font-size: @ib-font-size-base;
        font-weight: @ib-font-weight-normal;
        line-height: @ib-line-height-base;
    }
}
