@import "../../styles/variables";

.ib-activation-page {
    position: relative;
    border-radius: @ib-border-radius-small;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
    background: @white-white-50;

    &__loader {
        padding: 48px;
        width: 356px;
        text-align: center;

        .ib-spin {
            height: 64px;
            margin-bottom: 32px;
            span {
                font-size: 64px;
            }
        }

        .i-icon {
            margin-bottom: @ib-padding-xx-large;
            color: @red-red-500 !important;
        }

        &__title {
            color: @gray-gray-900;
            font-size: @ib-font-size-xx-large;
            font-weight: @ib-font-weight-bold;
            line-height: @ib-line-height-xx-large;
            margin-bottom: @ib-padding-base;
        }

        &__description {
            display: flex;
            flex-direction: column;
            gap: @ib-padding-base;
            color: @gray-gray-900;
            font-size: @ib-font-size-base;
            font-weight: @ib-font-weight-normal;
            line-height: @ib-line-height-base;
        }

        @media (max-width: @ib-breakpoint-small) {
            width: 100%;
            border-radius: @ib-border-radius-x-small;
            margin: @ib-padding-base;
        }
    }

    &__password {
        padding: @ib-padding-large;
        width: 468px;

        .ib-spin {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: @ib-border-radius-small;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: @white-white-50;
            opacity: 0.85;
            z-index: 2;

            @media (max-width: @ib-breakpoint-small) {
                border-radius: @ib-border-radius-x-large @ib-border-radius-x-large 0 0;
            }

            span {
                font-size: 64px;
            }
        }

        .ib-input {
            margin-bottom: @ib-padding-xxxx-small;
        }

        .ib-button {
            width: 100%;
        }

        &__title {
            color: @gray-gray-900;
            font-size: @ib-font-size-xx-large;
            font-weight: @ib-font-weight-bold;
            line-height: @ib-line-height-xx-large;
            margin-bottom: @ib-padding-base;

            @media (max-width: @ib-breakpoint-small) {
                text-align: center;
            }
        }

        &__input-label {
            color: @gray-gray-900;
            font-size: @ib-font-size-small;
            font-weight: @ib-font-weight-normal;
            line-height: @ib-line-height-base;
            margin-bottom: @ib-auth-page-input-label-margin;
            margin-top: @ib-padding-base;
        }

        &__form {
            margin: 32px 0;
        }

        @media (max-width: @ib-breakpoint-small) {
            width: 100%;
            border-radius: @ib-border-radius-x-large @ib-border-radius-x-large 0 0;
        }
    }
}
