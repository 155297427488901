@import "../../../styles/variables";

.ib-info {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    border-radius: @ib-border-radius-x-small;
    background: @orange-orange-100;
    padding: @ib-padding-small;
    color: @gray-gray-400;
    font-size: @ib-font-size-x-small;
    font-weight: @ib-font-weight-normal;
    line-height: @ib-line-height-x-small;

    &_error {
        .ib-icon {
            color: @red-red-600 !important;
        }
    }

    &_success {
        .ib-icon {
            color: @green-green-500 !important;
        }
    }
}
