.text-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.center-position() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.text-ellipsis(@row-count) {
  display: -webkit-box;
  -webkit-line-clamp: @row-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block-size(@size) {
  width: @size !important;
  height: @size !important;
}

.block-round(@size) {
  .block-size(@size);
  border-radius: @size / 2 !important;
}
